import React from 'react';
import {Container, Grid} from "@material-ui/core";

function Data(props) {
    return (
        <div>
            <Container maxWidth={'sm'}>
                <Grid container spacing={2}>

                </Grid>
            </Container>
        </div>
    );
}

export default Data;
