import './App.css';
import {Button, ButtonGroup, Container, Grid, Switch, TextField} from "@material-ui/core";
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom'
import React, {useEffect, useState} from "react";
import {toast, Toaster} from "react-hot-toast";
import {
    count_down, count_up, database, timer_init, timer_start, timer_value, toggle_timer_play, update_timer_length
} from "./lib/firebase";
import ZoomBtn from "./ZoomBTN";
import Zoom from "./Zoom";
import Data from "./Data";
import TimerSlider from "./TimerSlider";
import TimerLength from "./TimerLength";


function App() {
    const [mode, setMode] = useState(0);
    const [scoreboard, setScoreboard] = useState([]);
    const [playToggle, setPlayToggle] = useState(false);
    const [timerValue, setTimerValue] = useState('');

    const [participants, setParticipants] = useState(['Dez', 'Perceval', 'Timer', 'Group 1', 'Group 2', 'Investor 1', 'Investor 2', 'Investor 3', 'Investor 4', 'Investor 5', 'Investor 6', 'Investor 7', 'Investor 8',])

    useEffect(() => {
        if (localStorage.getItem('token') === undefined || localStorage.getItem('token') === null) {
            localStorage.setItem('token', 'no-token')
        }
    })

    useEffect(() => {
        document.title = "MediaClown"
    }, [])

    useEffect(() => {
        toggle_timer_play.on('value', snapshot => {
            console.log('snap', snapshot.val());
            setPlayToggle(snapshot.val());
        })

    }, [])


    useEffect(() => {
        timer_value.on('value', snapshot => {
            console.log('snap', snapshot.val());
            setTimerValue(snapshot.val());
        })
    }, [])

    useEffect(() => {
        database.ref('scoreboard').on('value', snapshot => {
            console.log(snapshot.val())
            setScoreboard(snapshot.val());
        })
    }, [])

    const setAppToken = () => {
        const token = prompt('Please enter application token');
        console.log(token);
        localStorage.setItem('token', token);
    }

    const handlePlayToggled = () => {
        setPlayToggle(!playToggle);
        console.log('TOGGLED')
        if (!playToggle) {
            database.ref().update({token: localStorage.getItem('token'), 'toggle_timer_play': true}).then(() => {
                toast.success('Timer Toggled')
            }).catch(() => {
                toast.error('Could not toggle timer')
            });
            // socket.emit('catnip', {
            //     remotePort,
            //     remoteIp,
            //     message: {
            //         address: '/toggle_timer_play',
            //         args: [{type: 'i', value: 1}]
            //     }
            // })

        } else {
            database.ref().update({token: localStorage.getItem('token'), 'toggle_timer_play': true}).then(() => {
                toast.success('Timer Toggled')
            }).catch(() => {
                toast.error('Could not toggle timer')
            });

        }
    }



    const startTimer = () => {
        const date = new Date();
        database.ref().update({token: localStorage.getItem('token'), 'timer_start': date.getTime()}).then(() => {
            toast.success('Timer Started')
        }).catch(() => {
            toast.error('Could not start timer')
        });
        // socket.emit('catnip', {
        //     remotePort,
        //     remoteIp,
        //     message: {
        //         address: '/timer_start',
        //         args: []
        //     }
        // })

    }

    const resetTimer = () => {
        const date = new Date();
        database.ref().update({token: localStorage.getItem('token'), 'timer_init': date.getTime()}).then(() => {
            toast.success('Timer Reset')

        }).catch(() => {
            toast.error('Could not reset timer')
        });
        // socket.emit('catnip', {
        //     remotePort,
        //     remoteIp,
        //     message: {
        //         address: '/timer_init',
        //         args: []
        //     }
        // })
    }


    const countDown = () => {
        const date = new Date();
        database.ref().update({token: localStorage.getItem('token'), 'count_down': date.getTime()}).then(() => {
            toast.success('Counting Down')
        }).catch(() => {
            toast.error('Could not set count down')
        });
        // socket.emit('catnip', {
        //     remotePort,
        //     remoteIp,
        //     message: {
        //         address: '/count_down',
        //         args: [{type: 'i', value: 0}]
        //     }
        // })

    }

    const countUp = () => {
        const date = new Date();
        database.ref().update({token: localStorage.getItem('token'), 'count_up': date.getTime()}).then(() => {
            toast.success('Counting Up')
        }).catch(() => {
            toast.error('Could not set count up')
        });
        // socket.emit('catnip', {
        //     remotePort,
        //     remoteIp,
        //     message: {
        //         address: '/count_up',
        //         args: [{type: 'i', value: 1}]
        //     }
        // })

    }

    const toggleZoom = (participant) => {
        const date = new Date();
        console.log(participant)
        const updateData = {}
        updateData[participant] = date.getTime();
        updateData['token'] = localStorage.getItem('token');
        database.ref().update(updateData).then(() => {
            toast.success('Toggled Zoom')
        }).catch(() => {
            toast.error('Could not toggle zoom')
        });
    }

    const toggleGalleryViewPercy = () => {
        const date = new Date();
        database.ref().update({'gallery': date.getTime(), token: localStorage.getItem('token')}).then(() => {
            toast.success('Toggled Gallery View')
        }).catch(() => {
            toast.error('Could not toggle gallery view')
        })
    }

    const updateScore = (team, inc) => {
        var newScore = 0
        if (inc) {
            newScore = scoreboard[team].score += 1;
        } else {
            newScore = scoreboard[team].score -= 1;
        }
        if (localStorage.getItem('token') === '4W61o92v1mVh') {
            database.ref(`scoreboard/${team}`).update({
                name: scoreboard[team].name, score: newScore
            }).then(() => toast.success('Score Updated')).catch(() => toast.error('Could not update score'))
        } else {
            toast.error('Could not update score')
        }

    }

    const showTimer = () => {
        const date = new Date();
        database.ref().update({'showTimer': date.getTime(), token: localStorage.getItem('token')})
            .then(() => toast.success('Timer Shown'))
            .catch(() => toast.error('Could not show timer.'))
    }

    const showScoreboard = () => {
        const date = new Date();
        database.ref().update({'showScoreboard': date.getTime(), token: localStorage.getItem('token')})
            .then(() => toast.success('Scoreboard Shown'))
            .catch(() => toast.error('Could not show scoreboard.'))
    }

    const showLeaderboard = () => {
        const date = new Date();
        database.ref().update({'showLeaderboard': date.getTime(), token: localStorage.getItem('token')})
            .then(() => toast.success('Leaderboard Shown'))
            .catch(() => toast.error('Could not show leaderboard.'))
    }

    const onFreeze = () => {
        const date = new Date();
        database.ref().update({'freeze': date.getTime(), token: localStorage.getItem('token')})
            .then(() => toast.success('Frozen'))
            .catch(() => toast.error('Could not freeze.'));
    }

    const onUnfreeze = () => {
        const date = new Date();
        database.ref().update({'unfreeze': date.getTime(), token: localStorage.getItem('token')})
            .then(() => toast.success('Unfrozen'))
            .catch(() => toast.error('Could not unfreeze.'));
    }

    const toggleGlitch = (glitchName) => {
        const date = new Date();
        const updateData = {}
        updateData[glitchName] = date.getTime();
        updateData['token'] = localStorage.getItem('token')
        database.ref().update(updateData)
            .then(() => toast.success(`Toggled ${glitchName}`))
            .catch(() => toast.error(`Could not toggle ${glitchName}`))
    }
    const MainContent = () => {
        return (<>

            <ButtonGroup disableElevation variant="outlined" color="primary" style={{padding: '10px'}}>
                <Button onClick={() => setMode(0)}>Timer</Button>
                <Button onClick={() => setMode(1)}>Zoom</Button>
                <Button onClick={() => setMode(2)}>Score</Button>
                <Button onClick={() => setMode(3)}>Other</Button>
            </ButtonGroup>
            {/*<Button variant={'outlined'} color={'primary'} onClick={() => setTimerMode(!timerMode)}>Switch Mode</Button>*/}
            <br/>
            <br/>
            <br/>
            {mode === 0 && <Container maxWidth={'sm'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2 style={{color: 'white', textAlign: "center", marginTop: '-24px'}}>{timerValue}</h2>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                        <Button onClick={startTimer} variant={'contained'}
                                style={{backgroundColor: 'green', color: 'white', margin: 'auto', height: '100px'}}
                                size={'large'}>Start Timer</Button>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                        <Button onClick={resetTimer} variant={'contained'}
                                style={{backgroundColor: 'red', color: 'white', margin: 'auto', height: '100px'}}
                                size={'large'}>Reset Timer</Button>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center', marginTop: '20px'}}>
                        <h3 style={{color: 'white'}}>Timer Enabled:</h3>
                        <Switch
                            style={{margin: 'auto'}}
                            checked={playToggle}
                            onChange={handlePlayToggled}
                            color="primary"
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                            size={"medium"}
                        />
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <h3 style={{color: 'white'}}>Timer Speed:</h3>
                        <TimerSlider/>
                    </Grid>
                    <TimerLength/>
                    <Grid item style={{marginTop: '20px'}} xs={12}/>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                        <Button onClick={countDown} variant={'contained'}
                                style={{backgroundColor: 'blue', color: 'white', margin: 'auto'}} size={'large'}>Count
                            Down</Button>
                    </Grid>
                    <Grid item xs={6} style={{textAlign: 'center'}}>
                        <Button onClick={countUp} variant={'contained'}
                                style={{backgroundColor: 'orange', color: 'white', margin: 'auto'}} size={'large'}>Count
                            Up</Button>
                    </Grid>
                </Grid>


            </Container>}
            {mode === 1 && <Container maxWidth={'sm'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button
                            onClick={toggleGalleryViewPercy}
                            fullWidth={true}
                            style={{backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'}}
                            size={'large'}>
                            Gallery View
                        </Button>
                    </Grid>
                    {participants.map(participant => (<ZoomBtn participant={participant} clicked={toggleZoom} key={participant}/>))}

                    {/*{participants.map(participant => (*/}
                    {/*    <Grid item xs={6} style={{textAlign: 'center'}} key={participant.name}>*/}
                    {/*        <Button onClick={() => participantClicked(participant.name)} variant={'contained'}*/}
                    {/*                style={{backgroundColor: `${participant.spotlight ? 'lightgreen' : 'lightgray'}`, color: 'black', margin: 'auto', height: '100px'}}*/}
                    {/*                size={'large'}*/}
                    {/*                fullWidth={true}*/}

                    {/*        >{participant.name}</Button>*/}
                    {/*    </Grid>*/}
                    {/*))}*/}
                </Grid>
            </Container>}
            {mode === 2 && <Container maxWidth={'sm'}>
                <Grid container spacing={2}>
                    {scoreboard.map((score, index) => (<>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <h1 style={{color: 'white'}}>{score.name}</h1>
                        </Grid>

                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button variant={'contained'} color={'primary'}
                                    onClick={() => updateScore(index, true)} size={'large'}>Up</Button>
                        </Grid>

                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <h1 style={{color: 'mediumaquamarine'}}>{score.score}</h1>
                        </Grid>


                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button variant={'contained'} color={'primary'}
                                    onClick={() => updateScore(index, false)} size={'large'}>Down</Button>
                        </Grid>

                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </>))}
                </Grid>
            </Container>}
            {mode === 3 && <>
                <Container maxWidth={'sm'}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={onFreeze} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Freeze</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={onUnfreeze} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Unfreeze</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={showTimer} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Show Timer</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={showScoreboard} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Show Scoreboard</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={showLeaderboard} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Show Leaderboard</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={() => toggleGlitch('Glitch 1')} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Glitch 1</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={() => toggleGlitch('Glitch 2')} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Glitch 2</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={() => toggleGlitch('Glitch 3')} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Glitch 3</Button>
                        </Grid>
                        <Grid item xs={6} style={{textAlign: 'center'}}>
                            <Button onClick={() => setAppToken()} variant={'contained'}
                                    style={{
                                        backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'
                                    }}
                                    size={'large'}
                                    fullWidth={true}

                            >Set App Token</Button>
                        </Grid>
                    </Grid>

                </Container>
            </>}
        </>)
    }

    return (<div>
        <Router>
            <Routes>
                <Route path={'/'} exact={true} element={<MainContent/>}/>
                <Route path={'/zoom'} exact={true} element={<Zoom/>}/>
                <Route path={'/data'} exact={true} element={<Data/>}/>
            </Routes>
        </Router>
        <Toaster position={'bottom-center'}/>
    </div>);


}

export default App;
