import React, {useEffect, useState} from 'react';
import {Slider} from "@material-ui/core";
import {update_timer_speed, database} from "./lib/firebase";
import {toast} from "react-hot-toast";


function TimerSlider(props) {

    const [testSpeed, setTestSpeed] = useState(5);


    useEffect(() => {
        return update_timer_speed.on('value', snapshot => {
            if (snapshot) {
                console.log('snap', snapshot.val());
                setTestSpeed(snapshot.val());
            }
        })
    }, [])


    const updateTimerSpeed = (e, value) => {
        database.ref().update({'update_timer_speed': value, token: localStorage.getItem('token')}).then(() => {
            toast.success('Timer Speed Updated')
        }).catch(err => {
            toast.error('Could not update timer speed')
            console.log(err)
        });
        // socket.emit('catnip', {
        //     remotePort,
        //     remoteIp,
        //     message: {
        //         address: '/update_timer_speed',
        //         args: [{type: 'f', value: value}]
        //     }
        // })

    }

    return (<Slider
        name={'timer_speed'}
        step={0.1}
        marks
        value={testSpeed}
        onChange={(e, value) => setTestSpeed(value)}
        min={0}
        max={10}
        valueLabelDisplay="auto"
        onChangeCommitted={updateTimerSpeed}
    />);
}

export default TimerSlider;
