import React, {useState} from 'react';
import {Button, Grid, TextField} from "@material-ui/core";
import {database} from "./lib/firebase";
import {toast} from "react-hot-toast";

function TimerLength(props) {

    const [timLength, setTimLength] = useState('');

    const timerLength = () => {
        try {
            database.ref().update({'update_timer_length': parseFloat((timLength)), token: localStorage.getItem('token')}).then(() => {
                toast.success('Timer Speed Length')
            }).catch(err => {
                toast.error('Could not update timer length')
                console.log(err)
            });
            // socket.emit('catnip', {
            //     remotePort,
            //     remoteIp,
            //     message: {
            //         address: '/update_timer_length',
            //         args: [{type: 'f', value: parseFloat(timLength)}]
            //     }
            // })

        } catch (e) {
            toast.error('An Error Occurred ')
        }
    }

    return (
        <>
            <Grid item xs={8}>
                <TextField onChange={(e) => setTimLength(e.target.value)} inputMode={'decimal'}
                           id="filled-basic" label="Timer Length (seconds)"
                           variant="filled" fullWidth={true} style={{backgroundColor: "white"}}/>
            </Grid>
            <Grid item xs={4}>
                <Button onClick={timerLength} variant={'contained'}
                        style={{backgroundColor: 'green', color: 'white'}}
                        size={'large'}>Update</Button>
            </Grid>
        </>

    );
}

export default TimerLength;
