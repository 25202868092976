import React from 'react';
import {Container, Grid} from "@material-ui/core";
import ZoomBtn from "./ZoomBTN";
import {database} from "./lib/firebase";
import {toast} from "react-hot-toast";

function Zoom(props) {
    const buttons = [
        'Show Perceval',
        'Hide Perceval',
        'Show dez',
        'Hide dez',
        'Show yu_info',
        'Hide yu_info',
        'Show G1',
        'Hide G1',
        'Show G2',
        'Hide G2',
        'Show P1',
        'Hide P1',
        'Show P2',
        'Hide P2',
        'Show P3',
        'Hide P3',
        'Show P4',
        'Hide P4',
        'Show P5',
        'Hide P5',
        'Show P6',
        'Hide P6',
    ]

    const buttonClicked = (name) => {
        const date = new Date();
        database.ref(name).set(date.getTime()).then(() => toast.success('Zoom Updated'));
    }

    return (
        <Container maxWidth={'sm'}>
            <Grid container spacing={2}>
                {buttons.map(button => (
                    <ZoomBtn clicked={() => buttonClicked(button)} participant={button} key={button}/>
                ))}
            </Grid>
        </Container>

    );
}

export default Zoom;
