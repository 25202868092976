import React from 'react';
import {Button, Grid} from "@material-ui/core";

function ZoomBtn({participant, clicked}) {
    return (
        <Grid item xs={6} style={{textAlign: 'center'}} key={participant}>
            <Button onClick={() => clicked(participant)} variant={'contained'}
                    style={{backgroundColor: `lightgray`, color: 'black', margin: 'auto', height: '100px'}}
                    size={'large'}
                    fullWidth={true}

            >{participant}</Button>
        </Grid>
    );
}

export default ZoomBtn;
