import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: "AIzaSyBNxB5KujvhBBlkdi4C6C38BzZl-VnGJrQ",
    authDomain: "mc-catnip.firebaseapp.com",
    databaseURL: "https://mc-catnip-default-rtdb.firebaseio.com",
    projectId: "mc-catnip",
    storageBucket: "mc-catnip.appspot.com",
    messagingSenderId: "97168214909",
    appId: "1:97168214909:web:cfc7f8a57feb08d594da14"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Auth exports
// export const auth = firebase.auth();
// export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

// Firestore exports
export const firestore = firebase.firestore();
export const database = firebase.database();
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const fromMillis = firebase.firestore.Timestamp.fromMillis;
export const increment = firebase.firestore.FieldValue.increment;

export const timer_value = database.ref('timer_value');
export const timer_start = database.ref('timer_start');
export const timer_init = database.ref('timer_init');
export const update_timer_speed = database.ref('update_timer_speed');
export const update_timer_length = database.ref('update_timer_length');
export const toggle_timer_play = database.ref('toggle_timer_play');
export const count_down = database.ref('count_down');
export const count_up = database.ref('count_up');
export const participantsFB = database.ref('participants');
